import BaseModal from '@/components/base/baseModal';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { ScrollShadow, useDisclosure } from '@nextui-org/react';
import { ReactNode } from 'react';

export default ({ children, privacyAgreement, title }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div className="flex justify-start items-center" onClick={onOpen}>
        {children}
      </div>
      <BaseModal isOpen={isOpen} onClose={onClose} title={title} classNames={{mask:'!z-[9999999]'}}>
        <div className="mt-4 mb-10 px-4 ">
          <ScrollShadow hideScrollBar className="h-[50vh] sm:h-[40vh]">
            <div className="my-2 min-h-[30vh] text-foreground">
              <FeaturesRenderText data={privacyAgreement} />
            </div>
          </ScrollShadow>
        </div>
      </BaseModal>
    </>
  );
};

interface IProps {
  children: ReactNode;
  privacyAgreement: any;
  title: string;
}

import type { SVGProps } from 'react';

export function Doubt(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.98828 0.315674C4.64219 0.315674 0.308594 4.64927 0.308594 9.99536C0.308594 15.3415 4.64219 19.675 9.98828 19.675C15.3344 19.675 19.668 15.3415 19.668 9.99536C19.668 4.64927 15.3367 0.315674 9.98828 0.315674ZM9.67891 15.8454C9.06016 15.8454 8.55625 15.3321 8.55625 14.7016C8.55625 14.0688 9.05781 13.5579 9.67891 13.5579C10.2977 13.5579 10.8016 14.0688 10.8016 14.7016C10.8016 15.3344 10.2977 15.8454 9.67891 15.8454ZM12.8125 8.65942C12.6555 8.95474 12.475 9.2102 12.2594 9.42349C12.0461 9.63911 11.6641 10 11.1109 10.5063C10.9586 10.6493 10.8367 10.7758 10.7453 10.8836C10.6539 10.9915 10.5859 11.0922 10.5391 11.1813C10.4945 11.2704 10.4594 11.3618 10.4359 11.4532C10.4125 11.5446 10.375 11.7016 10.3258 11.9266C10.2414 12.4071 9.97188 12.6461 9.52422 12.6461C9.28984 12.6461 9.09531 12.5688 8.93594 12.4094C8.77656 12.2524 8.69687 12.0204 8.69687 11.7086C8.69687 11.3219 8.75547 10.9844 8.87266 10.7008C8.98984 10.4172 9.14453 10.1688 9.33672 9.95317C9.52891 9.73989 9.78906 9.48208 10.1172 9.18911C10.4055 8.9313 10.6117 8.73677 10.7383 8.60317C10.8672 8.47192 10.975 8.32661 11.0617 8.16255C11.1484 8.00083 11.193 7.82739 11.193 7.6352C11.193 7.26724 11.0594 6.95552 10.7922 6.70239C10.525 6.44927 10.1805 6.32036 9.75859 6.32036C9.26406 6.32036 8.90078 6.44927 8.66875 6.70474C8.43438 6.9602 8.2375 7.33755 8.07812 7.83442C7.92578 8.35708 7.63516 8.61724 7.20859 8.61724C6.95781 8.61724 6.74453 8.52583 6.57109 8.34536C6.39766 8.16255 6.31094 7.96567 6.31094 7.75474C6.31094 7.31646 6.44688 6.87349 6.72109 6.42349C6.99297 5.97349 7.39375 5.60083 7.91875 5.30552C8.44375 5.01021 9.05547 4.86255 9.75625 4.86255C10.4055 4.86255 10.982 4.98677 11.4789 5.23286C11.9781 5.47896 12.3625 5.81646 12.6344 6.24067C12.9063 6.66489 13.0422 7.12661 13.0422 7.62583C13.0445 8.01724 12.9695 8.36177 12.8125 8.65942Z"
        fill="currentColor"
      />
    </svg>
  );
}

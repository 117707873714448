import InviteViewNew from '@/pages/invite/index/components/InviteView_new';
import useStateHooks from '@/pages/invite/index/useStateHooks';

export default () => {
  const { rebateRules, currentInvitationCodeData,currentInvitationCodeLoading } = useStateHooks();
  return (
    <div className="relative h-full bg-inviteBGColor">
      <div className="relative h-full">
        <InviteViewNew
          currentInvitationCodeData={currentInvitationCodeData}
          rebateRules={rebateRules}
          currentInvitationCodeLoading={currentInvitationCodeLoading}
        />
      </div>
    </div>
  );
};

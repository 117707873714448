import type { SVGProps } from 'react';

export function InviteAward(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.828125"
        width="48"
        height="48"
        rx="24"
        fill="#1B71EB"
        fill-opacity="0.1"
      />
      <path
        d="M19.9768 17.4C19.9768 17.4 22.1745 19.2229 25 19.2229C27.8255 19.2229 30.086 17.4 30.086 17.4C30.086 17.4 32.5348 15.2 31.279 14.2571C30.0232 13.3143 28.1395 14.5714 28.1395 14.5714C28.1395 14.5714 26.6953 13 25 13C23.3047 13 21.9233 14.5714 21.9233 14.5714C21.9233 14.5714 20.0396 13.3143 18.7838 14.2571C17.528 15.2 19.9768 17.4 19.9768 17.4ZM35.4231 29.5314C35.4231 29.5314 36.2394 27.1429 34.3557 23.2457C32.3464 19.16 29.6465 19.0971 29.6465 19.0971C29.6465 19.0971 27.6372 20.3543 25 20.3543C22.3628 20.3543 20.4163 19.0971 20.4163 19.0971C20.4163 19.0971 17.6536 19.0971 15.6443 23.3086C13.8234 27.08 14.5769 29.5943 14.5769 29.5943C14.5769 29.5943 10.3071 35 25 35C39.6929 35 35.4231 29.5314 35.4231 29.5314ZM24.9372 32.7371C21.9861 32.7371 19.6001 30.3486 19.6001 27.4571C19.6001 24.5657 21.9861 22.1771 24.9372 22.1771C27.8883 22.1771 30.2744 24.5657 30.2744 27.4571C30.2744 30.3486 27.8883 32.7371 24.9372 32.7371ZM21.6093 27.2057L25.0628 30.6629L28.5162 27.2057L25.0628 23.7486L21.6093 27.2057Z"
        fill="url(#paint0_linear_531_2240)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_531_2240"
          x1="25"
          y1="13"
          x2="25"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4F98FF" />
          <stop offset="1" stop-color="#0054CB" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { AntDesignQrcodeOutlined } from '@/assets/icons/comm/AntDesignQrcodeOutlined';
import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import { Doubt } from '@/assets/icons/mine/Doubt';
import { InviteAward } from '@/assets/icons/mine/InviteAward';
import { InviteFriendRegistration } from '@/assets/icons/mine/InviteFriendRegistration';
import { InviteFriends } from '@/assets/icons/mine/InviteFriends';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import BaseSkeleton from '@/components/base/baseSkeleton';
import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { FormatUtils } from '@/utils/format';
import { Button } from '@nextui-org/react';
import { history } from '@umijs/max';
import { useTheme } from 'next-themes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModel } from 'umi';
import AIInviteRulesDrawer from '../components/AIInviteRulesDrawer';

export default ({
  currentInvitationCodeData,
  rebateRules,
  currentInvitationCodeLoading,
}: any) => {
  const { isPc } = useModel('system');
  const { t } = useTranslation();
  //
  const { theme } = useTheme();

  //
  const [rebateList] = useState([
    {
      text: t('邀请好友'),
      icon: InviteFriends,
    },
    {
      text: t('好友注册并交易'),
      icon: InviteFriendRegistration,
    },
    {
      text: t('获得奖励'),
      icon: InviteAward,
    },
  ]);
  return (
    <div className="w-full h-full relative">
      <div>
        <div className="w-[100%] min-h-[300px]">
          <img
            width={'100%'}
            src={require(`@/assets/img/invite/${
              theme !== 'dark'
                ? 'inviteTOP_bg_white.png'
                : 'inviteTOP_bg_dark.png'
            }`)}
            alt=""
          />
        </div>

        <div className="w-full absolute top-[0px]">
          {/* '好友推荐项目|推荐好友&赢取奖励' */}
          <BaseTopNav
            title={''}
            rightNode={
              <div>
                <AIInviteRulesDrawer
                  title={t('规则说明')}
                  privacyAgreement={rebateRules}
                >
                  <Doubt className="text-backContrastColor  cursor-pointer p-4 box-content" />
                </AIInviteRulesDrawer>
              </div>
            }
          />

          <div className="w-full">
            <div className="w-full flex flex-wrap justify-center">
              <div className="w-full flex flex-wrap justify-center">
                <span className="text-[24px] leading-[26px] font-semibold text-backContrastColor text-center">
                  {t('邀请好友，一起赚币')}
                </span>
              </div>

              <div className="w-fit px-4 mt-4 bg-inviteSubTitleBGColor rounded-[20px]">
                <span className="flex text-inviteSubTitleColor text-[14px] leading-[22px] ">
                  {/* {t('在所有交易中赚取高达{{data}}%的佣金', {
                    data: currentInvitationCodeData?.maxRebatePercentage,
                  })} */}
                  {t('在所有交易中赚取高达') }
                  {
                    (currentInvitationCodeLoading ? (
                      <BaseSkeleton isLoaded={currentInvitationCodeLoading}>
                        <div className=" flex justify-between bg-inviteSubTitleBGColor w-[27px] rounded-md h-[22px]">
                          <span className="text-xs font-bold text-auxiliaryTextColor">
                            &nbsp;
                          </span>
                        </div>
                      </BaseSkeleton>
                    ) : (
                      currentInvitationCodeData?.maxRebatePercentage+'%'
                    )) }
                    {t('的佣金')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 主体内容 */}
      <div
        className={`relative mt-4 px-4 w-full h-full flex flex-wrap gap-y-4 absolute !h-fit bottom-[0px]  ${
          !isPc ? 'pb-[25%]' : ''
        }`}
      >
        {/* 我的邀请 */}
        <div className="w-full !h-fit rounded-[8px]  pb-4 bg-inviteSubContentBg">
          <div className="flex w-full justify-start rounded-tl-lg">
            <span className="rounded-tl-lg bg-inviteRichTextBGColor text-inviteSubTitleColor px-4 text-[14px] leading-[20px] font-semibold">
              {t('我的邀请')}
            </span>
          </div>
          <div className="mt-4 flex gap-x-[12px] ">
            <div className="w-full flex flex-wrap  justify-center">
              <span className="w-full text-center text-backContrastColor text-[18px] leading-[26px] font-semibold !h-[26px]">
                {RenderUtil.FormatAmount(
                  (currentInvitationCodeData as any)?.popularizeAmountTotal,
                )}
                {ProjectSetting.APP_DEFAULT_CURRENCY}
              </span>
              <span className="w-full text-center text-auxiliaryTextColor text-[12px] leading-[20px]">
                {t('总奖励({{data}})', {
                  data: ProjectSetting.APP_DEFAULT_CURRENCY,
                })}
              </span>
            </div>
            <div className="w-full flex flex-wrap  justify-center">
              <span className="w-full text-center text-backContrastColor text-[18px] leading-[26px] font-semibold !h-[26px]">
                {currentInvitationCodeData?.totalInvitations ?? 0}
              </span>
              <span
                className="w-full flex items-center justify-center text-auxiliaryTextColor text-[12px] leading-[20px]"
                onClick={() => {
                  //   history.push(PageEnum.INVITEFRIENDSLIST REBATE_RANKING);
                  history.push(PageEnum.REBATE_DETAIL);
                }}
              >
                <div className="max-w-[100px]">{t('邀请人总计(人)')}</div>

                <PhCaretRight />
              </span>
            </div>
          </div>
        </div>
        {/* 分享邀请 */}
        <div className="w-full !h-fit rounded-[8px]  pb-4 bg-inviteSubContentBg">
          <div className="flex w-full justify-start rounded-tl-lg">
            <span className="rounded-tl-lg bg-inviteRichTextBGColor text-inviteSubTitleColor px-4 text-[14px] leading-[20px] font-semibold">
              {t('分享邀请')}
            </span>
          </div>
          {/*  */}
          <div className="w-full my-4 flex flex flex-wrap px-4">
            <span className="w-full text-start text-auxiliaryTextColor text-[12px] leading-[20px]">
              {t('推荐码')}
            </span>
            <div className="w-full flex justify-between !min-h-[21px] ">
              <span className="!max-w-[80%] text-[14px] leading-[20px] font-semibold ">
                {currentInvitationCodeLoading ? (
                  <BaseSkeleton isLoaded={currentInvitationCodeLoading}>
                    <div className=" flex justify-between bg-backgroundAuxiliaryColor w-[100px] rounded-md h-[20px]">
                      <span className="text-xs font-bold text-auxiliaryTextColor">
                        &nbsp;
                      </span>
                    </div>
                  </BaseSkeleton>
                ) : (
                  currentInvitationCodeData?.popularizeCode ?? '--'
                )}
                {/* {currentInvitationCodeData?.popularizeCode} */}
              </span>
              <PhCopyFill
                className="text-backContrastColor"
                onClick={(e) => {
                  FormatUtils.copyText(
                    currentInvitationCodeData?.popularizeCode,
                  );
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
          {/*  */}
          <div className="w-full flex flex-wrap px-4">
            <span className="w-full text-start text-auxiliaryTextColor text-[12px] leading-[20px] ">
              {t('我的邀请链接')}
            </span>
            <div className="w-full flex justify-between !min-h-[40px] ">
              <span className="!max-w-[80%] text-[14px] leading-[20px] font-semibold">
                {currentInvitationCodeLoading ? (
                  <BaseSkeleton isLoaded={currentInvitationCodeLoading}>
                    <div className=" flex justify-between bg-backgroundAuxiliaryColor w-[100px] rounded-md h-[40px]">
                      <span className="text-xs font-bold text-auxiliaryTextColor">
                        &nbsp;
                      </span>
                    </div>
                  </BaseSkeleton>
                ) : (
                  currentInvitationCodeData?.popularizeUrl ?? '--'
                )}
                {/* {currentInvitationCodeData?.popularizeUrl} */}
              </span>
              <PhCopyFill
                className="text-backContrastColor"
                onClick={(e) => {
                  FormatUtils.copyText(
                    currentInvitationCodeData?.popularizeUrl,
                  );
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
        </div>
        {/* 获得返佣 */}
        <div className="w-full !h-fit rounded-[8px]  pb-4 bg-inviteSubContentBg">
          <div className="flex w-full justify-start rounded-tl-lg">
            <span className="rounded-tl-lg bg-inviteRichTextBGColor text-inviteSubTitleColor px-4 text-[14px] leading-[20px] font-semibold">
              {t('获得返佣')}
            </span>
          </div>
          <div className="mt-4 flex grid grid-cols-3">
            {rebateList.map((item, index) => (
              <div
                key={index}
                className="px-4 w-full flex flex-wrap justify-center items-start text-auxiliaryTextColor text-[12px] leading-[20px]"
              >
                <item.icon />
                <div className="w-full text-center mt-2 flex justify-center items-start h-full ">
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* 按钮 */}
      <div
        className={`flex justify-between mt-4 bottom-0 left-0 right-0 bg-bgColor  p-4 ${
          !isPc ? 'fixed' : ''
        }`}
      >
        <Button
          className="mainColorButton"
          onClick={() => {
            history.push(PageEnum.INVITEFRIENDS);
          }}
        >
          {t('邀请好友')}
        </Button>
        <div
          onClick={() => {
            history.push(PageEnum.INVITEQRCODE);
          }}
          className="bg-background !w-[48px] !h-[48px] p-[12px] cursor-pointer ml-2 flex justify-center items-center text-foreground border-[1px] border-solid border-borderColor rounded-[8px]"
        >
          <AntDesignQrcodeOutlined className="!w-[24px] !h-[24px]" />
        </div>
      </div>
    </div>
  );
};

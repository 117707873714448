import type { SVGProps } from 'react';

export function InviteFriends(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.166504"
        width="48"
        height="48"
        rx="24"
        fill="#1B71EB"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.4279 24.0647C34.6232 24.3724 34.7269 24.7294 34.7269 25.0938V31.6799C34.7269 32.4437 34.4235 33.1763 33.8834 33.7164C33.3433 34.2565 32.6108 34.5599 31.8469 34.5599H16.4869C15.7231 34.5599 14.9906 34.2565 14.4505 33.7164C13.9104 33.1763 13.6069 32.4437 13.6069 31.6799V25.101C13.6069 24.7569 13.6995 24.419 13.8748 24.1229C14.0502 23.8267 14.3019 23.5831 14.6036 23.4176C14.9054 23.2521 15.2461 23.1708 15.5901 23.1821C15.9341 23.1934 16.2687 23.297 16.5589 23.482L24.1468 28.3199L31.7773 23.4733C31.9902 23.3381 32.2276 23.2461 32.476 23.2026C32.7244 23.1591 32.979 23.165 33.2251 23.2199C33.4713 23.2749 33.7042 23.3777 33.9105 23.5227C34.1169 23.6676 34.2927 23.8518 34.4279 24.0647ZM28.9669 14.3999C29.7308 14.3999 30.4633 14.7033 31.0034 15.2434C31.5435 15.7835 31.8469 16.5161 31.8469 17.2799V22.0799L24.1828 26.8799L16.4869 22.0799V17.2799C16.4869 16.5161 16.7904 15.7835 17.3305 15.2434C17.8706 14.7033 18.6031 14.3999 19.3669 14.3999H28.9669ZM21.0467 17.28H27.2867C27.7667 17.28 28.0067 17.52 28.0067 18C28.0067 18.48 27.7667 18.72 27.2867 18.72H21.0467C20.5667 18.72 20.3267 18.48 20.3267 18C20.3267 17.52 20.5667 17.28 21.0467 17.28ZM23.4467 20.6399H21.0467C20.5667 20.6399 20.3267 20.8799 20.3267 21.3599C20.3267 21.8399 20.5667 22.0799 21.0467 22.0799H23.4467C23.9267 22.0799 24.1667 21.8399 24.1667 21.3599C24.1667 20.8799 23.9267 20.6399 23.4467 20.6399Z"
        fill="url(#paint0_linear_531_2227)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_531_2227"
          x1="24.1669"
          y1="14.3999"
          x2="24.1669"
          y2="34.5599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4F98FF" />
          <stop offset="1" stop-color="#0054CB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
